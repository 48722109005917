<template>
  <div class="main-vr">
	
	<div id="vanta-container">
  
	  <div id="fullpage">
	  <div class="section bg-none" id="section0">
		  <div class="inner-section-parent first-slide">
			  <div class="top-slide-left">
				  <h4 class="tl dirr ta">   {{$t('virtual_reality')}}</h4>
				  
				  <h4 class="tl dirr ta">{{$t('al-Abbass_holy_shrine')}}</h4>
				  <p class="tx dirr ta">{{$t('vr_home_description')}}</p>
				  <a class="top-slider-button ta dirr"  :href="'https://alkafeel.net/panorama/?lang='+$i18n.locale" target="_blank" data-featherlight="#videoInModal">  {{$t('enter_vr')}}</a>
			  </div>
			  <div class="top-slide-right">
				  <div class="slide-right-img-container slide-in-fwd-center">
					  <img class="" src='/assets/img/imam-vr.webp' alt="">
				  </div>
			  </div>
			  
			  
		  
  
		  </div>
  </div>
  
  
  
	  
		  
	  
  </div>
</div>
	</div>
	  
	  </template>
	  
	  
	  
  <script>
	  
	  import Vanta from 'vanta/dist/vanta.globe.min.js'; // Replace with the appropriate Vanta module
  
	  // Make sure window.THREE is defined, e.g. by including three.min.js in the document head using a <script> tag
	  
	  
	  export default {
		  mounted() {
	Vanta({
	  el: '#vanta-container', // The ID or class of the Vanta container element
	  mouseControls: true,
  touchControls: true,
  heightAuto: false,
  gyroControls: false,
  minHeight: 600.00,
  minWidth: 300.00,
  scale: 1.00,
  scaleMobile: 1.00,
  color: 0xffffff,
  backgroundColor: 0x3c3c3c,
  points: 16.00,
  maxDistance: 25.00,
  spacing: 20.00
	  // Add any effect-specific options here
	});
  }
	  }
  </script>
  
  <style>
  
  

  .hide-lightbox {
	  display:none;
  }
  
  
  .section {
	  text-align: center;
  }
  .inner-section-parent {
	  margin:0 auto;
	  position:relative;
	  max-width:1200px;
	   vertical-align: middle;
	  height: 600px;
	  display: flex;
	  align-items: center;
	  justify-content: center;
  
  }
  #main-logo {
	  cursor:pointer;
  }
  
  #home-top,
  .gradient-bg, body {
  
	  background: linear-gradient(
		  135deg,
		  #555c1ebf 0%,
		  #083a44 36%,
		  #04242b 57%,
		  #0e1b24 100%
	  );
	  filter: progid:DXImageTransform.Microsoft.gradient(
			  startColorstr="#01a2ba",
			  endColorstr="#0175bf",
			  GradientType=1
		  );
  }
  
  
  /* THREE JS BG STYLES*/
  #canvas {
		position: fixed;
	  top: 0;
	  height: 600px;
	  overflow: hidden;
	  max-width:100%;
  }
  
  .top-slider-container .swiper-slide{
	  background:none;
  }
  
  .top-slide-left {
	  max-width: 400px;
	  color:white;
	  text-align:left;
	  padding-right:10px;
	  margin-right: 40px;
	  float:right;
	  text-align: right;
  }
  .top-slide-left h4{
	  font-size:2rem;
	  margin-bottom:0px;
  }
  .top-slide-left h2{
	  font-size:5rem;
	  margin:0;
	  line-height:1;
	  margin-bottom: 10px;
  }
  .top-slide-left p{
	  line-height:1.5;
	  font-size:1.2rem;
	  margin-top:0px;
	  margin-bottom:50px !important;
  }
  .top-slider-button {
	  background: #B1BD52;
	  color: white!important;
	  border-radius: 8px;
	  font-size: 1.1rem;
	  line-height: 1;
	  padding: 18px 20px 20px;
	  border: 0px;
	  height: inherit;
	  letter-spacing: 1px;
	  text-transform:  uppercase;
	  text-decoration:none;
  }
  .top-slider-button:hover {
	  background: #818b2e;
  }
  
  .slide-right-img-container {
	  width:inherit;	
  }
  .top-slide-right {
	  float:left;
  }
  
  .top-slide-right img{
	  max-width:;
	  position:relative;
	  transform: translate3d(0,0,0);
  }
  
  .slide-title {
		  position: absolute;
	  top: 80px;
  }
  #fullpage {
	background: linear-gradient( 135deg, #555c1ec4 0%, #083a44bd 36%, #04242bd9 57%, #0e1b24c4 100% ) !important;
  }
  
  /**
   * ----------------------------------------
   * Media Quaries
   * ----------------------------------------
   */ 
  @media (max-width:960px) {
	  .inner-section-parent {
	  height: 100%
	  }
	  .fullpage {
	height: 600px;
  }

  @media (min-width: 600px) and (max-width: 768px) {
  
	.top-slide-right img {
    max-width: 60% !important;
	}
}

@media (min-width: 769px) and (max-width: 960px) {
  
  .top-slide-right img {
  max-width: 55% !important;
  }
}

  .bg-none {
	height: 600px;
  }
	  .top-slide-right {
			  float: none;
	  }
	  .top-slide-left {
	  position: absolute;
	  bottom: 3vh;
	  text-align: center;
	  max-width: 85%;
	  margin-right: 0;
	  padding-right: 0;
	  float: none;
	  left: 0;
	  right: 0;
	  margin: 0 auto;
	  }
	  .first-slide .top-slide-left{
		   bottom: 9vh;
	  }
	  .top-slide-left h2 {
		  font-size: 2.5rem;
		  position: relative;
	  z-index: 5;
	  }
	  .top-slide-left h4 {
		  font-size: 1.5rem;
		  z-index: 5;
		  position: relative;
	  }
	  .top-slide-left p {
		  font-size: 1rem;
		  margin-bottom: 20px;
	  }
	  .top-slider-button {
		  /* position:absolute; */
		  position: relative;
		  bottom: -20px;
		  /* width: 300px; */
	  }
  
	  .top-slide-right {
		  position: absolute;
	  top: 3vh;
		  padding:20px;
		  float: none;
	  left: 0;
	  right: 0;
	  }
	  .top-slide-right img{
		  max-width:100%;
		  z-index: 1;
	  }
  }
  </style>